import React, { useEffect, useState } from 'react';
import './App.scss';
import { Header } from './components/header';
import { HolidaysForm } from './components/holidays-form/holidays-form';
import { analytics } from './lib/firebase';

import { v4 as uuidv4 } from 'uuid';

function App() {

  const [route, setRoute] = useState('form');

  useEffect(() => {
    analytics.setUserId(uuidv4());
  }, []);


  return route === 'form' ? (
    <>
      <Header />
      <section className="section">
        <HolidaysForm onSubmit={() => setRoute('success')} />
      </section>
    </>
  ) : (
    <div className="section success-message">
      <h2>העברנו את הבקשה לטיפול</h2>
      <p>נשלח לך הודעה לאחר שנעדכן את שעות הפעילות. אנחנו מאחלים לך חג שמח!</p>
    </div>
  )
}

export default App;
