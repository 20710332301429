import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useField, FieldConfig } from 'formik';

import styles from './audio-upload-field.module.scss';
import { PlayerButton } from '../player-button';
import { UploadButton } from '../audio-upload-button';
import { Select } from '../../../../base/atoms/select';

interface AudioUploadFieldProps extends FieldConfig {
    defaultAudioSrc?: string;
}

export const AudioUploadField: FC<AudioUploadFieldProps> = ({defaultAudioSrc="", name}) => {

    const [field,, helper] = useField(name);
    const [loading, setLoading] = useState(false);
    const [acceptUserInput, setAcceptUserInput] = useState(false);

    const [src, setSrc] = useState(defaultAudioSrc);

    useEffect(() => {
        if(typeof field.value === "object")
            setSrc(URL.createObjectURL(field.value));
        else {
            setSrc(defaultAudioSrc);
        }
    },[field.value, defaultAudioSrc])


    const handleUpload: (e: ChangeEvent<HTMLInputElement>) => void = e => {
        setLoading(true);
        helper.setValue(e.target.files?.[0]);

        setTimeout(() => {
            setLoading(false);
        }, 2000)
    };

    return (
        <div className={styles.root}>
            <h5>הקלטת מענה קולי</h5>
            <span className={styles.subtitle}>ניתן להשתמש בהקלטה שיצרנו בשבילכם או להעלות קטע חדש</span>
            <Select value={acceptUserInput ? "1" : "0"} onChange={e => {
                if(e.target.value === "1") {
                    setAcceptUserInput(true);
                } else {
                    setAcceptUserInput(false);
                    helper.setValue(undefined);
                }
            }} className={styles.select}>
                <option value="0">הקלטת ברירת מחדל</option>
                <option value="1">הקלטה מותאמת אישית</option>
            </Select>
            {(field.value || !acceptUserInput) && <PlayerButton className={styles.play} src={src} loading={loading} />}
            {acceptUserInput && (
                <div className={styles.upload}>
                    <UploadButton className={styles.upload} onChange={handleUpload}>
                        העלה קטע חדש
                    </UploadButton>
                </div>
            )}
        </div>
    )
}