import { firestore } from '../lib/firebase';
import uploadIvrAudioRecord from './upload-ivr-audio-record';

interface ChangeRequest {
  company: {
    name: string,
    id: string,
    ivr_number: string,
    contact_name: string,
    contact_email: string,
  },
  dates: {
    [key: number]: {
      [key: number]: {
        open: boolean;
        start: string;
        end: string;
      }
    }
  },
  assets: {
    [key: string]: File|undefined
  }
}

export default async function (data: ChangeRequest) {
   try {
    const doc = firestore.collection("changeRequests").doc();

    const { assets, ...docData } = data;

    const docAssets = (await Promise.all(
      Object.entries(assets)
        .filter(([, file]) => file !== undefined)
        .map(([key, file]) => uploadIvrAudioRecord(key, data.company.id, file as File))
    )).reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: current.url
      }
    }, {});

    await doc.set({
        ...docData,
        assets: docAssets,
    })
    return {
      status: "done",
      data: {
        id: doc.id
      }
    };
   } catch(e) {
      return {
        status: "error",
        error: e,
      }
   }
};