import * as Yup from 'yup';
 
export const validation = Yup.object().shape({
  company: Yup.object({
    name: Yup.string().required('הזינו את שם החברה, כדי שנוכל לאמת שאתם שלחתם את הבקשה'),
    id: Yup.string().min(9, 'ח.פ לא תקין - וודאו שהזנתם 9 ספרות').required('הזינו ח.פ, כדי שנוכל לאמת שאתם שלחתם את הבקשה'),
    ivr_number: Yup.string().required("הזינו את המספר עליו יתבצע השינוי"),
    contact_name: Yup.string().required('הזינו איש קשר, אליו נפנה במקרה שיהיו לנו שאלות'),
    contact_email: Yup.string().email('כתובת האימייל לא תקינה').required('הזינו כתובת אימייל, למקרה שיהיו לנו שאלות נוספות'),
    contact_tel: Yup.string().required("הזינו את מספר הטלפון של איש קשר")
  }),
});

export const initialValues = {
  holidays: {
    rosh_hashanah: true,
    sukkot: false,
    kippur: false,
  },
  assets: {
    rosh_hashanah: undefined,
    kippur: undefined,
    sukkot: undefined,
  },
  company: {
    name: "",
    id: "",
    ivr_number: "",
    contact_name: "",
    contact_email: "",
    contact_tel: "",
  },
  dates: {
    9: {
      18: {
        open: true,
        start: "08:00",
        end: "18:00"
      },
      19: {
        open: false,
        start: "08:00",
        end: "18:00"
      },
      20: {
        open: false,
        start: "08:00",
        end: "18:00",
      },
      27: {
        open: false,
        start: "08:00",
        end: "18:00",
      },
      28: {
        open: false,
        start: "08:00",
        end: "18:00",
      }
    },
    10: {
      2: {
        open: false,
        start: "08:00",
        end: "18:00",
      },
      3: {
        open: false,
        start: "08:00",
        end: "18:00",
      },
      9: {
        open: false,
        start: "08:00",
        end: "18:00",
      },
      10: {
        open: false,
        start: "08:00",
        end: "18:00",
      }
    }
  }
}