import React, { TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './textarea.module.scss';


export const Textarea = ({className,...props}: TextareaHTMLAttributes<HTMLTextAreaElement>) => (
    <textarea
        className={classNames(styles.root, className)}
        {...props}
    />
);