import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from '../../base/atoms/input';
import { Field } from '../../base/form';

import styles from './holiday-form.module.scss';
import { Holiday } from './components/holiday';
import { Day } from './components/day';
import { TimeRange } from './components/time-range';
import { AudioUploadField } from './components/audio-upload-field';
import { Textarea } from '../../base/atoms/textarea';
import classNames from 'classnames';
import { validation, initialValues } from './schemas';
import sendChangeRequest from '../../services/send-change-request';
import { LoadingOutlined } from '@ant-design/icons';
import { analytics } from '../../lib/firebase';
// import uploadIvrAudioRecord from '../../services/upload-ivr-audio-record';


export const HolidaysForm = ({onSubmit}: {onSubmit: (values: any) => void}) => {

  return (
    <Formik
      validationSchema={validation}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const response = await sendChangeRequest(values);
        analytics.logEvent("submit", { success: response.status === "done", time: Date.now() });
        if(response.status === "done") {
          onSubmit({id: response.data?.id, ...values});
        }
      }}
    >
      {
        props => {
          if(props.isSubmitting) {
            return (
              <div className={styles.submitting}>
                <LoadingOutlined className={styles.icon} />
                <span>שולחים את הבקשה...</span>
              </div>
            )
          }
          // const getFirstErrorKey = (object:any, keys: any[] = []): any => {
          //   const firstErrorKey = Object.keys(object)[0];
          //   if (isObject(object[firstErrorKey])) {
          //     return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
          //   }
          //   return [...keys, firstErrorKey].join('.');
          // };

          // if(!props.isValid && props.submitCount > 0 ) {
          //   const firstErrorKey = getFirstErrorKey(props.errors);
          //   console.log(firstErrorKey);
          //   if (global.window.document.getElementsByName(firstErrorKey).length) {
          //     global.window.document.getElementsByName(firstErrorKey)[0].focus();
          //   }
          // }
          

          return (
          <Form className={styles.root}>
            <section className={styles.section}>
              <Field
                name="company.name"
                label="שם בית העסק"
                description="ככה הלקוחות שלכם קוראים לכם"
              >
                <Input type="text" />
              </Field>
              <Field
                name="company.id"
                label="ח.פ"
              >
                <Input type="text" />
              </Field>
              <Field
                name="company.ivr_number"
                label="טלפון"
                description="המספר שאליו לקוחות מחייגים אליכם"
              >
                <Input type="tel" />
              </Field>
              <div className={styles.whitespace}>
                <Field
                  name="company.contact_name"
                  label="שם איש קשר"
                >
                  <Input type="text" />
                </Field>
                <Field
                  name="company.contact_email"
                  description="נשלח לכם עותק של בקשת השינוי לכתובת הזו"
                  label="כתובת אימייל איש קשר"
                >
                  <Input type="email" />
                </Field>
                <Field
                  name="company.contact_tel"
                  label="טלפון איש קשר"
                >
                  <Input type="tel" />
                </Field>
              </div>
            </section>
            <section className={styles.section}>
              <h2 className={styles['section__title']}>שעות הפעילות</h2>
              <Holiday name="holidays.rosh_hashanah" title="ראש השנה" subtitle="18-20 ספטמבר">
                <Day title="ערב חג" subtitle="יום שישי, 18 ספטמבר" name="dates[9][18].open">
                  <TimeRange>
                    <Field size="small" name="dates.9.18.start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates.9.18.end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="ראש השנה" subtitle="יום שבת, 19 ספטמבר" name="dates[9][19].open">
                  <TimeRange>
                    <Field size="small" name="dates[9][19].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[9][19].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="ראש השנה" subtitle="יום ראשון, 20 ספטמבר" name="dates[9][20].open">
                  <TimeRange>
                    <Field size="small" name="dates[9][20].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[9][20].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <AudioUploadField name="assets.rosh_hashanah" defaultAudioSrc="/assets/rosh_hashanah.wav" />
              </Holiday>
              <Holiday name="holidays.kippur" title="יום כיפור" subtitle="27-28 ספטמבר">
                <Day title="ערב כיפור" subtitle="יום ראשון, 27 ספטמבר" name="dates[9][27].open">
                  <TimeRange>
                    <Field size="small" name="dates[9][27].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[9][27].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="יום כיפור" subtitle="יום שני, 28 ספטמבר" name="dates[9][28].open">
                  <TimeRange>
                    <Field size="small" name="dates[9][28].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[9][28].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <AudioUploadField name="assets.kippur" defaultAudioSrc="/assets/kippur.wav" />
              </Holiday>
              <Holiday name="holidays.sukkot" title="סוכות" subtitle="2-10 אוקטובר">
                <Day title="ערב חג" subtitle="יום שישי, 2 אוקטובר" name="dates[10][2].open">
                  <TimeRange>
                    <Field size="small" name="dates[10][2].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[10][2].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="סוכות" subtitle="יום שבת, 3 אוקטובר" name="dates[10][3].open">
                  <TimeRange>
                    <Field size="small" name="dates[10][3].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[10][3].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="שמחת תורה" subtitle="יום שישי, 9 אוקטובר" name="dates[10][9].open">
                  <TimeRange>
                    <Field size="small" name="dates[10][9].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[10][9].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <Day title="שמחת תורה" subtitle="יום שבת, 10 אוקטובר" name="dates[10][10].open">
                  <TimeRange>
                    <Field size="small" name="dates[10][10].start" label="משעה">
                      <Input type="time" />
                    </Field>
                    <Field size="small" name="dates[10][10].end" label="עד שעה">
                      <Input type="time" />
                    </Field>
                  </TimeRange>
                </Day>
                <AudioUploadField name="assets.sukkot" defaultAudioSrc="/assets/sukkot.wav" />
              </Holiday>
            </section>
            <section className={styles.section}>
              <Field name="notes" label="הערות">
                <Textarea rows={8} placeholder="למשל, אני רוצה להפעיל עקוב אחרי למספר..." />
              </Field>
            </section>
            <button type="submit" className={classNames("primary", styles.primaryButton)}>עדכן שעות</button>
          </Form>
        )}
      }
    </Formik>
  )
}