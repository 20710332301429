import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './input.module.scss';


export const Input = ({className, type, ...props}: InputHTMLAttributes<HTMLInputElement>) => {

    // replace time input with text input on desktop devices
    const isMobile = (/mobi/i.test(navigator.userAgent.toLowerCase()));
    const finalType = type === "time" && !isMobile ? "text" : type;

    return (
        <input
            className={classNames(styles.root, className)}
            type={finalType}
            {...props}
        />
    );
}