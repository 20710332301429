import React, { FC, cloneElement } from 'react';
import { FieldConfig, useField } from 'formik';

import styles from './field.module.scss';
import classNames from 'classnames';

export interface FieldProps {
    description?: string;
    label?: string;
    size?: "small",
}

export const Field: FC<FieldProps&FieldConfig> = ({children, description, label, size, ...props}) => {

    const [field, meta] = useField(props.name);
    const fieldElement = cloneElement(children as React.ReactElement<any>, field);

    return label ? (
        <label
            className={classNames({
                [styles.root]: true,
                ...(size && {[styles[size]]: true})
            })}
        >
            <span className={styles.label}>{label}</span>
            {description && <span className={styles.description}>{description}</span>}
            {fieldElement}
            {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
        </label>
    ) : fieldElement;
}