import React, { ButtonHTMLAttributes, MouseEvent } from 'react';

import { useAudio } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './player-button.module.scss';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Pause } from './pause.svg';


interface PlayerButtonProps {
    file?: File;
    loading?: boolean;
    src?: string;
}

export const PlayerButton = ({className, file, loading = false, src = "", onClick, ...props}: PlayerButtonProps&ButtonHTMLAttributes<HTMLButtonElement>) => {

    const [audio, state, controls] = useAudio({
        src,
        autoPlay: false,
    });

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);
        toggle();
    }

    const icon = loading ? <LoadingOutlined className={styles.icon} /> : state.paused ? <Play className={styles.icon} /> : <Pause className={styles.icon} />

    const toggle = () => state.paused ? controls.play() : controls.pause();

    return (
        <button type="button"  className={classNames(styles.root, className)} onClick={handleClick} {...props}>
            {audio}
            {icon}
        </button>
    );
}