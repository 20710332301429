import { storage } from '../lib/firebase';

/**
 * upload record and get download url
 * @param companyID 
 * @param record 
 * @return String download link
 */
export default async function (name: string, companyID: string, record: File) {
    const recordRef = storage.ref(companyID).child(`${name}_${new Date().valueOf()}`);
    await recordRef.put(record);

    return {
        name,
        url: await recordRef.getDownloadURL()
    };
};