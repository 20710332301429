import React, { InputHTMLAttributes, useRef, FC } from 'react';

import styles from './upload-button.module.scss'
import classNames from 'classnames';

export const UploadButton: FC<InputHTMLAttributes<HTMLInputElement>> = ({children, className, ...props})  => {
    const ref = useRef<HTMLInputElement|null>();
    return (
        <>
            <input {...props} className={classNames(styles.input, className)} type="file" ref={input => {
                ref.current = input;
            }} />
            <button type="button" className={styles.root} onClick={() => ref.current?.click()}>{children}</button>
        </>
    )
}