import React, { FC } from 'react';
// import { Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

import styles from './holiday.module.scss';
import classNames from 'classnames';
import { useField } from 'formik';

interface HolidayProps {
    title: string;
    subtitle: string;
    name: string;
}

// const GroupTitle: FC = ({children, ...props}) => <Typography.Title style={{marginBottom: 0}} level={3} {...props}>{children}</Typography.Title>

export const Holiday: FC<HolidayProps> = ({children, name, title, subtitle}) => {
    const [field,,helper] = useField(name);

    return (
        <div
            tabIndex={!field.value ? 0 : undefined}
            className={classNames({
                [styles.root]: true,
                [styles.collapsed]: !field.value,
            })}
            onClick={() => helper.setValue(true)}
        >
            {
                !field.value ? (
                    <>
                        <div>
                            <h2>{title}</h2>
                            <span className={styles.subtitle}>{subtitle}</span>
                        </div>
                        <PlusCircleFilled className={styles['expand-icon']} />
                    </>
                ) : (
                     <>
                        <h2>{title}</h2>
                        {children}
                    </>
                )
            }
        </div>
    )
};