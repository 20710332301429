import React, { FC } from 'react';
import { Switch } from '../../../../base/atoms/switch';

import styles from './day.module.scss';
import { useField } from 'formik';


interface DayProps {
    name: string;
    subtitle: string;
    title: string;
}

export const Day: FC<DayProps> = ({children, name, subtitle, title}) => {
    const [field, , helper] = useField(name);    

    return (
        <div className={styles.root}>
            <header className={styles.header}>
                <div>
                    <h5>{title}</h5>
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
                <div className={styles.switch}>
                    <span>{field.value ? 'פתוחים' : 'סגורים'}</span>
                    <Switch
                        value={field.value}
                        checked={field.value}
                        onChange={() => helper.setValue(!field.value)}
                    />
                </div>
            </header>
            {
                field.value && (
                    <div className={styles.body}>
                        {children}
                    </div>
                )
            }
        </div>
    );
};