import React from 'react';
import classNames from 'classnames';

import styles from './header.module.scss';


export const Header = () => (
	<header className={styles.root}>
		<div className={classNames("section", styles.section)}>
			<img src="/logo.png" alt="iPracticom" className={styles.logo} />
			<div className={styles.content}>
				<h1 className={classNames("title", styles.title)}>עדכון שעות פעילות לחגים</h1>
				<h3 className={styles.subtitle}>לפניכם טופס להתאמת שעות המענה הטלפוני בתקופת החגים</h3>
			</div>
		</div>
	</header>
)